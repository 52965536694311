<template>
  <div class="wrap">
    <!-- <van-nav-bar
      title="环境卫生"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    /> -->
    <!-- 表单 -->
    <div class="inputs">
      <van-cell-group>
        <van-field
          v-model="dataform.Householder"
          required
          label="户主"
          placeholder="请输入户主"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="dataform.FamilySize"
          type="number"
          required
          label="家庭人数"
          placeholder="请输入家庭人数："
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
                <van-field name="FamilyKind" label="住户类型">
          <template #input>
            <van-radio-group v-model="dataform.FamilyKind" direction="horizontal">
              <van-radio name="1">常住户</van-radio>
              <van-radio name="2">租赁户</van-radio>
              <van-radio name="3">空挂户</van-radio>
              <van-radio name="4">空房户</van-radio>
              <van-radio name="5">公租</van-radio>
              <van-radio name="6">廉租</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="IsHavePets" label="宠物">
          <template #input>
            <van-radio-group v-model="dataform.IsHavePets" direction="horizontal">
              <van-radio name="1">有</van-radio>
              <van-radio name="2">无</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="dataform.PetsInfo"
          rows="1"
          autosize
          label="宠物信息"
          type="textarea"
          placeholder="请输入宠物信息"
          v-if="dataform.IsHavePets == 1"
        />
        <van-field name="IsHaveCar" label="车辆">
          <template #input>
            <van-radio-group v-model="dataform.IsHaveCar" direction="horizontal">
              <van-radio name="1">有</van-radio>
              <van-radio name="2">无</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="dataform.CarInfo"
          rows="1"
          autosize
          label="车辆信息"
          type="textarea"
          v-if="dataform.IsHaveCar == 1"
          placeholder="请输入车辆信息"
        />
                <van-field name="IsShop" label="开店">
          <template #input>
            <van-radio-group v-model="dataform.IsShop" direction="horizontal">
              <van-radio name="1">有</van-radio>
              <van-radio name="2">无</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="dataform.ShopInfo"
          rows="1"
          autosize
          label="开店信息"
          type="textarea"
          v-if="dataform.IsShop == 1"
          placeholder="请输入开店信息"
        />
        <van-field
          v-model="dataform.Remark"
          rows="1"
          autosize
          label="备注"
          type="textarea"
          placeholder="请输入备注"
        />
        <van-field name="Status" label="状态">
          <template #input>
            <van-radio-group v-model="dataform.Status" direction="horizontal">
              <van-radio name="1">启用</van-radio>
              <van-radio name="2">禁用</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-button round block type="info" color="#617bfa" style="font-size: 16px" @click="SaveZh()">保存</van-button>
      </van-cell-group>
    </div>
    <!-- <TipsMiniProject :show.sync="showTips" />
    <TipsMiniProject :show="false" /> -->
    <!-- <div style="margin: 16px;"></div> -->
  </div>
</template>

<script>
import config from "@/config";
import axios from "axios";
import { getwgToken, setwgToken } from "@/utils/auth";
import { Toast } from "vant";
import { WeGetOrganTree } from "@/api/Organ";
import { SaveBuildFamily,GetBuildFamily} from "@/api/wangge";
export default {
  components: {
    // TipsMiniProject
  },
  data() {
    return {
      value: "",
      show: false,
      fieldValue: "",
      cascaderValue: "",
      fileList: [],
      uploadImgs: [],
      message: "",
      showTips: false,
      // 选择地区事件
      orgonList: [],
      dataform: {
        HId: 0,
        RId: "",
        BFaId: "",
        OrganCode: "",
        BId: "",
        BUId: "",
        BFId: "",
        BRId: "",
        FamilyKind: "1",
        Householder: "",
        FamilySize: "",
        IsHavePets: "2",
        PetsInfo: "",
        IsHaveCar: "2",
        CarInfo: "",
        IsShop: "2",
        ShopInfo: "",
        Status: "1",
        Remark: "",
      },
      xqxqform:{
          accToken:"",
          rId:undefined
      },
    };
  },
  created() {
    // this.getOrgonTree();
    this.xiquXq()
    // this.dataform.OpenID = getOpenId();
    // this.dataform.SKId = this.$route.params.Id;
  },
  methods: {
      xiquXq: function(){
        console.log(this.$route.query);
        // if (this.$route.query.add == 1) {
            this.xqxqform.bfaId = this.$route.query.bfaId;
            this.xqxqform.accToken = getwgToken();
            GetBuildFamily(this.xqxqform)
            .then((res) => {
            if (res.data.code == 0) {
                this.dataform = res.data.data;
                // 状态
                this.dataform.Status = res.data.data.Status + "";
                // 住户类型
                this.dataform.FamilyKind = res.data.data.FamilyKind + "";
                // 宠物
                this.dataform.IsHavePets = res.data.data.IsHavePets + "";
                // 车辆
                this.dataform.IsHaveCar = res.data.data.IsHaveCar + "";
                // 开店
                this.dataform.IsShop = res.data.data.IsShop + "";
                console.log(res.data.data);
                if (this.$route.query.add == 1 ) {
                  this.dataform.HId = 0;
                  this.dataform.BFaId = 0;
                  this.dataform.Householder = "";
                  this.dataform.FamilyKind = "1";
                  this.dataform.FamilySize = "";
                  this.dataform.IsHavePets = "2";
                  this.dataform.PetsInfo = "";
                  this.dataform.IsHaveCar = "2";
                  this.dataform.CarInfo = "";
                  this.dataform.IsShop = "2";
                  this.dataform.ShopInfo = "";
                  this.dataform.Status = "1";
                  this.dataform.Remark = "";
                  console.log(this.dataform);
                } 
            }
        })
        .catch(() => {});
        // }
      },
    // 表头返回按钮事件
    onClickLeft() {
      Toast;
      window.history.go(-1);
    },
    afterRead(file) {
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file);
      // 添加请求头
      axios.post(config.apiUploadUrl, formData, c).then((res) => {
        if (res.data.code === 0) {
          var arr = this.uploadImgs;
          arr.push(res.data.data)
          this.uploadImgs = arr;
        }
        console.log(this.uploadImgs);
        console.log(res);
        this.dataform.Thumb = res.data.data
        console.log(this.dataform.Thumb);
      });
    },
    // axios
    // UploadImgs().then(res => {
    //   console.log(res);
    // });
    // 此时可以自行将文件上传至服务器
    //   console.log(file);
    //  }
    // 地区选择完成后 触发事件
    onFinish({ selectedOptions }) {
        this.show = false;
        this.fieldValue = selectedOptions.map((option) => option.OName).join('/');
        this.Organcode = selectedOptions.map((option) => option.OCode).join('/');
        console.log(this.Organcode);
        this.dataform.OrganName = this.fieldValue
        this.dataform.OrganCode = this.Organcode
        console.log(this.fieldValue);
    },
    getOrgonTree(){
        WeGetOrganTree().then(res => {
            this.orgonList = res.data.data;
        })
    },
    SaveZh() {
        console.log(this.dataform);
        if (this.dataform.Householder == "" || this.dataform.Householder == null || this.dataform.Householder == undefined) {
          Toast.fail("请输入户主");
        } else if(this.dataform.FamilySize == "" || this.dataform.FamilySize == null || this.dataform.FamilySize == undefined) {
          Toast.fail("请输入家庭人数");
        } else{
        this.dataform.Status = Number(this.dataform.Status);
        this.dataform.IsHavePets = Number(this.dataform.IsHavePets);
        this.dataform.IsHaveCar = Number(this.dataform.IsHaveCar);
        this.dataform.IsShop = Number(this.dataform.IsShop);
        this.dataform.FamilyKind = Number(this.dataform.FamilyKind);
        this.dataform.accToken = getwgToken();
        console.log(this.dataform);
        SaveBuildFamily(this.dataform).then((res) => {
            console.log(res);
            if (res.data.code == 0) {
            Toast.success("保存成功!");
            //   this.$router.push("/wangge/jiating");
            this.itemmark = JSON.stringify(this.dataform.BRId);
            console.log(this.itemmark);
            this.$router.push({
                name:'jiating' ,
                query:{BRId:this.itemmark}
            })
            } else {
            Toast.fail(res.data.msg);
        }
      });
      }
    },
  },
};
</script>

<style scoped>
.van-radio{
  margin: 5px 5px ;
  width: 70px;
}
.wrap {
  background: #f7f8fa;
}
.inputs {
  padding: 10px 10px;
}
.submit {
  position: relative;
  top: -35px;
  font-size: 14px;
  color: #646566;
  line-height: 24px;
  padding: 0 30px 50px 15px;
}
.imgs {
  padding: 10px 0;
}
</style>